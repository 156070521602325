import {
  getContentfulData,
  isValidArrayWithData,
  QueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import { SeoInfoProps } from '../../../pages/static/[...staticPage]'
import { pathStaticList } from '../../helpers'

interface StaticProps {
  contentType: string
  type: string
}

export const getStaticContent = async ({ contentType, type }: StaticProps) => {
  const queryClient = new QueryClient()
  const contentOptions: object = {
    options: {
      content_type: contentType,
      'fields.url[all]': `/static/${type}`,
      'fields.idDelFormato': 1,
      'fields.platform': 'Web Unimarc eComm'
    }
  }

  await queryClient.prefetchQuery(`contenful_${contentType}`, () => {
    return getContentfulData(contentOptions)
  })
  const contentData: object = queryClient.getQueryData(
    `contenful_${contentType}`
  )

  const seoInfo: SeoInfoProps = {
    pageTitle: contentData?.['items'][0]?.fields?.title || type,
    description: contentData?.['items'][0]?.fields?.description || '',
    robotTag: contentData?.['items'][0]?.fields?.robot || ''
  }
  let notFoundType = false
  const isDynamicPage = pathStaticList.some((path) => {
    return path?.name !== type
  })
  const isADocument =
    contentData?.['includes']?.['Asset'] &&
    contentData?.['includes']?.Entry?.[0]?.fields?.file?.sys?.id
  const layoutType: string = isADocument ? 'asset' : 'text'
  const name: string = isDynamicPage ? layoutType : type

  const filteredContentData: Array<object> = isADocument
    ? contentData?.['includes']?.Entry?.map((data) => {
        const entryId: string = data?.fields?.file?.sys?.id
        const assetsData: Array<object> = contentData?.[
          'includes'
        ]?.Asset?.filter((asset) => {
          return asset?.sys?.id === entryId
        })
        const entryData: object = { ...data?.fields?.terms?.content }

        return {
          entryId,
          entryData,
          assetsData,
          pageTitle: seoInfo?.pageTitle
        }
      })
    : contentData?.['includes']?.Entry?.map((data) => {
        const content = data?.fields?.text?.content?.map((subContentData) => {
          if (isValidArrayWithData(Object.keys(subContentData?.data))) {
            const assetId = subContentData?.data?.target?.sys?.id
            const assetData = contentData['includes'].Asset.find((asset) => {
              return asset.sys.id === assetId
            })

            return {
              ...subContentData,
              data: {
                ...subContentData.data,
                file: assetData?.fields?.file
              }
            }
          }

          return subContentData
        })

        return {
          ...content,
          pageTitle: seoInfo?.pageTitle,
          customHtml: data?.fields?.html || null
        }
      })

  if (!filteredContentData) {
    notFoundType = true
  }

  return {
    notFoundType,
    filteredContentData,
    seoInfo,
    name
  }
}
