import { useState } from 'react'
import { useRouter } from 'next/router'
import {
  sleep,
  useContentful,
  useList,
  useMobile,
  useSession,
  useSessionState
} from '@smu-chile/pkg-unimarc-hooks'
import { postNameToList } from '@smu-chile/pkg-unimarc-hooks/services'
import {
  IProductInList,
  ISelectedList
} from '@smu-chile/pkg-unimarc-components/shared/interfaces/ILists'
import { v4 as uuidv4 } from 'uuid'
import { EMPTY_ORANGE_IMG } from 'shared/utils/constanst'

/**
 * Manage properties like open modal, identify if is a new list, show tooltip, save list's name
 * and save the item to be added to the list. Also show login's modal if the user doesn't has an active session
 * also return values wich are required by de AddToListModal component
 * call the POST API for creating new list or add product into a list
 * @returns createListButton,
    innerWidth
    isLoggedIn
    listData
    notificationAddToList
    openModalCreateList
    selectedListName
    handleAddProductsToList
    handleCreateNewList
    handleOnClosed
    handleOpenMyListsModal
    setCreateListButton
    setNotificationAddToList
    setOpenModalCreateList
 */
export const useAddProductToList = () => {
  const router = useRouter()
  const { isLoggedIn } = useSession()
  const { innerWidth } = useMobile()

  const { data: list, refetch: refetchUseList } = useList({
    order: 1,
    page: 1,
    perPage: 15
  })

  const emptyOrangeImg = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-orange-img',
    options: {
      'sys.id': EMPTY_ORANGE_IMG[0]
    },
    type: 'assets'
  })

  const emptyOrangeImage =
    emptyOrangeImg?.data?.['items']?.[0]?.fields?.file?.url || ''

  const [openModalCreateList, setOpenModalCreateList] = useState(false)
  const [createListButton, setCreateListButton] = useState(false)
  const [notificationAddToList, setNotificationAddToList] = useState(false)
  const [selectedListName, setSelectedListName] = useSessionState(
    'selectedListName',
    ''
  )
  const [itemToAdd, setItemToAdd] = useState<IProductInList>()

  const handleOpenMyListsModal = (item: IProductInList) => {
    const url = new URL(window.location.href)
    if (!isLoggedIn) {
      url.searchParams.append('login', 'true')
      router.push(
        {
          pathname: url.pathname.toString(),
          query: url.searchParams.toString()
        },
        undefined,
        {
          shallow: true
        }
      )
    } else {
      const calculatdQuantity = getQuantity(
        item?.quantity,
        item?.measurenmentUnit || item?.measurementUnit,
        item?.unitMultiplier
      )
      item.quantity = calculatdQuantity
      setItemToAdd(item)
      setOpenModalCreateList(true)
    }
  }

  const getQuantity = (
    quantity: number,
    measurementUnit: string,
    unitMultiplier: number
  ) => {
    let quatityToReturn = 1
    if (typeof quantity === 'string') {
      const parseQuantity = parseFloat(quantity)
      if (measurementUnit.toUpperCase() === 'KG') {
        quatityToReturn = Math.round(parseQuantity / unitMultiplier)
      } else {
        quatityToReturn = parseQuantity
      }
    }
    return quatityToReturn
  }

  const handleAddProductsToList = async (selected: ISelectedList) => {
    setSelectedListName(selected?.name)
    await postNameToList({
      name: selected?.name,
      id: selected?.id,
      products: [itemToAdd]
    })
    await refetchUseList()
    await sleep(500)
    setOpenModalCreateList(false)
    setNotificationAddToList(true)
  }

  const handleCreateNewList = async (
    value: string,
    selected: ISelectedList
  ) => {
    const listId = uuidv4()
    const selectedProducts = selected?.products
    selectedProducts.push(itemToAdd)
    setSelectedListName(value)
    await postNameToList({ name: value, id: listId, products: [itemToAdd] })
    await refetchUseList()
    await sleep(500)
    setOpenModalCreateList(false)
    setNotificationAddToList(true)
  }

  const handleOnClosed = () => {
    setNotificationAddToList(false)
  }

  return {
    createListButton,
    emptyOrangeImage,
    innerWidth,
    isLoggedIn,
    listData: list?.data,
    notificationAddToList,
    openModalCreateList,
    selectedListName,
    handleAddProductsToList,
    handleCreateNewList,
    handleOnClosed,
    handleOpenMyListsModal,
    setCreateListButton,
    setNotificationAddToList,
    setOpenModalCreateList
  }
}
