import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  Backbone,
  Pagination,
  PaginationProps,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { clickPlp } from 'shared/utils/googleAnalytics'

export interface CustomPaginationProps extends Partial<PaginationProps> {
  currentPage: number
  isLoadingPage: boolean
  isVisableButtonLeft: boolean
  isVisableButtonRight: boolean
  isVisableButtons: boolean
  items: (string | number)[]
  pageQuantity: number
  urlParams?: string
}

export const CustomPagination = ({
  isLoadingPage,
  urlParams,
  ...propsNavigation
}: CustomPaginationProps) => {
  const router = useRouter()
  const path = router?.asPath?.replace(/\?.*/, '')

  if (isLoadingPage) {
    return (
      <Row customWidth='25'>
        <Backbone
          borderRadius='20px'
          height={30}
        />
      </Row>
    )
  }

  const handleNextPage = () => {
    clickPlp('Siguiente', 'paginacion')
  }
  const handlePrevPage = () => {
    clickPlp('Anterior', 'paginacion')
  }
  const handleOnClick = (number: number) => {
    clickPlp(number, 'paginacion')
  }

  return (
    <Pagination
      handleNextPage={handleNextPage}
      handleOnClick={handleOnClick}
      handlePrevPage={handlePrevPage}
      linkWrapper={Link}
      path={path}
      urlParams={urlParams}
      {...propsNavigation}
    />
  )
}
